import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import ReturnSale from './ReturnSale';
import { returnSale, toggleSaleAction } from '../../../actions/sales_actions';
import _get from 'lodash/get';

function mapStateToProps(state) {
  return {
    open: state.sales[`actionReturn-SaleOpen`],
    openManual: state.sales[`actionReturn-SaleManualOpen`],
    requestSend: state.requestState[`requestReturn-SaleSend`],
    cardif: _get(
      state,
      'globalSetting.orgData.voluntary_insurance.cardif.article.id'
    ),
    teleshoppingCardif: _get(
      state,
      'globalSetting.orgData.teleshopping_voluntary_insurance.cardif.article.id'
    ),
    renewalCardif: _get(
      state,
      'globalSetting.orgData.voluntary_insurance_renewal.cardif.article.id'
    ),
    cardifLayerTwoId: _get(
      state,
      'globalSetting.orgData.voluntary_insurance_layer_two_article_id'
    ),
    revokeSaleCausals: state.globalSetting.orgData.sale_revoke_causes,
    secureSaleArticleId: state.globalSetting.orgData.secure_sale_article_id,
    canReturnSaleManual: state.authentication.currentUser.canReturnSaleManual,
    canReturnArticlesSaleManual: state.authentication.currentUser.canReturnArticlesSaleManual,
    canReturnSale: state.authentication.currentUser.canReturnSale,
    b2bSelectedPos: state.globalSetting.orgData.b2b_selected_pos,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    returnSale: bindActionCreators(returnSale, dispatch),
    toggleSaleAction: bindActionCreators(toggleSaleAction, dispatch)
  };
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ReturnSale);

import SalesReturnList from './SalesReturnList';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import { withRouter } from 'react-router-dom';
import {
  fetchSalesRevoke,
  revokeSaleReturn,
  approveSaleReturn,
  reviewSaleReturn,
  returnArticlesSalesMassive
} from '../../../actions/sales_revoke_actions';
import { fetchGeographicLocations } from '../../../actions/geographic_location_actions';
function mapStateToProps(state) {
  const { salesRevoke, meta } = state.salesRevoke;
  return {
    salesRevoke,
    meta,
    requestSend: state.requestState.requestSend,
    requestRevokeSaleReturnSend: state.requestState.requestRevokeSaleReturnSend,
    requestSaveSaleReturnSend: state.requestState.requestSaveSaleReturnSend,
    canReviewReturnSale: state.authentication.currentUser.canReviewReturnSale,
    canApproveReturnSale: state.authentication.currentUser.canApproveReturnSale,
    canRevokeReturnSale: state.authentication.currentUser.canRevokeReturnSale,
    geographicLocations: state.geographicLocation.list,
    companyName: state.globalSetting.orgData.company_name,
    canReturnArticlesSalesMassive:
    state.authentication.currentUser.canReturnArticlesSalesMassive
  };
}

function mapDispatchToProps(dispatch) {
  return {
    fetchSalesRevoke: bindActionCreators(fetchSalesRevoke, dispatch),
    revokeSaleReturn: bindActionCreators(revokeSaleReturn, dispatch),
    approveSaleReturn: bindActionCreators(approveSaleReturn, dispatch),
    reviewSaleReturn: bindActionCreators(reviewSaleReturn, dispatch),
    returnArticlesSalesMassive: bindActionCreators(
      returnArticlesSalesMassive,
      dispatch
    ),
    fetchGeographicLocations: bindActionCreators(
      fetchGeographicLocations,
      dispatch
    )
  };
}

export default compose(
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(SalesReturnList);

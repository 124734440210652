import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Fade, Grid, TextField, withStyles } from '@material-ui/core';
import _find from 'lodash/find';
import _filter from 'lodash/filter';
import CustomDialog from '../../shared/Modals/CustomDialog';
import Toggle from '../../shared/Buttons/Toggle';
import ReturnPartially from './ReturnPartially/ReturnPartially';
import SimpleSelect from '../../shared/Selects/SimpleSelect';
import mapListToOptions from '../../../helpers/mapListToOptions';
import { mapReturnCancelCausals } from '../../../helpers/mappings/mapToOptions';
import _get from 'lodash/get';

class ReturnSale extends Component {
  constructor(props) {
    super(props);
    this.state = {
      observation: '',
      return_total: props.canReturnArticlesSaleManual && !props.canReturnSaleManual ? false : true,
      articles: [],
      cause: -1,
      items: [],
      nItems: 0
    };
  }

  newArticle = () => {
    const { items, nItems } = this.state;
    this.setState({
      items: [...items, { idx: nItems, id: -1, amount: -1, cause: -1 }],
      nItems: nItems + 1
    });
  };

  _handleSave = return_total => {
    const { sale, batch, openManual } = this.props;
    const { observation, cause, articles } = this.state;
    if (!batch) {
      const params = {
        sale: {
          id: sale.id,
          observation: observation,
          return_type: openManual ? 'returned_manual' : 'returned'
        }
      };
      if (return_total) {
        params.sale = {
          ...params.sale,
          cause: cause,
          articles: []
        };

        this.props.returnSale(params);
      } else {
        params.sale = { ...params.sale, articles };

        this.props.returnSale(params, 'partially').then((e) => {
          const deferreds = e.split(':')[1] ? e.split(':')[1].split(', ').map(Number) : [];
          const deferredArticlesIds = deferreds.map((deferred) => {
            return sale.saleDetails.find((sd) => sd.deferred === deferred);
          }).filter(Boolean).map((sd) => sd.id);

          const deferredItems = this.state.items.filter((item) => {
            return !deferredArticlesIds.includes(item.id);
          });
          const deferredArticles = this.state.items.filter((item) => {
            return !deferredArticlesIds.includes(item.id);
          });

          this.setState({ items: deferredItems, articles: deferredArticles });
          if (deferredItems.length === 0) {
            this.newArticle();
          }
        });
      }
    } else {
      // TODO: ITERATIVE REQUESTS TO API
    }
  };

  _handleClose = () => {
    const { open } = this.props;
    if (!this.props.requestSend) {
      const modalName = open ? 'Return-Sale' : 'Return-SaleManual';
      this.props.toggleSaleAction(modalName, false);
      this.setState({
        observation: '',
        return_total: true,
        articles: [],
        cause: -1
      });
    }
  };

  _handleChange = e => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };

  _handleActive = a => {
    this.setState({
      return_total: a,
      observation: '',
      items: [],
      nItems: 0,
      cause: -1,
      articles: []
    });
  };
  _handleArticles = articles => {
    this.setState({ articles });
  };

  _isReturningTotally = () => {
    const { hasPendingPartialReturn } = this.props;
    const { return_total } = this.state;
    if (hasPendingPartialReturn) return false;
    return return_total;
  };

  _disabledSubmit = return_total => {
    const { articles, cause } = this.state;
    if (return_total) {
      return cause == -1 ? true : false;
    }
    if (!return_total) {
      if (articles.length == 0) return true;
      const any = _find(
        articles,
        e => e.id == -1 || e.amount == -1 || e.cause == -1
      );
      return any ? true : false;
    }
    return false;
  };

  _getCausal = sale => {
    if (!sale) return [];
    const { cardif, teleshoppingCardif, renewalCardif, cardifLayerTwoId } = this.props;
    const details = sale.saleDetails;
    const contains_cardif = _find(
      details,
      a =>
        [cardif, teleshoppingCardif, renewalCardif, cardifLayerTwoId].includes(a.article.id) &&
        a.status == 'delivered'
    );
    const other_articles = _find(
      details,
      a =>
        ![cardif, teleshoppingCardif, renewalCardif, cardifLayerTwoId].includes(a.article.id) &&
        a.status == 'delivered'
    );
    const returnCancelCausals = mapReturnCancelCausals(
      this.props.revokeSaleCausals,
      cardif,
      teleshoppingCardif,
      renewalCardif,
      cardifLayerTwoId
    );
    let filteredCausalList = returnCancelCausals;
    // CARDIFF & OTHER ARTICLES
    if (contains_cardif && other_articles) {
      filteredCausalList = _filter(returnCancelCausals, i => {
        if (i.only.length == 0 && i.allExcept.length == 0) return true;
        return false;
      });
    }
    // CARDIFF ONLY
    if (contains_cardif && !other_articles) {
      filteredCausalList = _filter(returnCancelCausals, i => {
        if (i.allExcept.includes(cardif)) return false;
        if (i.only.includes(cardif)) return true;
        if (i.only.length == 0) return true;
        return false;
      });
    }
    // ARTICLES ONLY W/O CARFIFF
    if (!contains_cardif && other_articles) {
      filteredCausalList = _filter(returnCancelCausals, i => {
        if (i.allExcept.includes(cardif)) return true;
        if (i.only.length == 0) return true;
        return false;
      });
    }
    return mapListToOptions(filteredCausalList);
  };

  render() {
    const {
      open = false,
      openManual = false,
      requestSend,
      sale,
      hasPendingPartialReturn,
      cardif,
      teleshoppingCardif,
      renewalCardif,
      cardifLayerTwoId,
      secureSaleArticleId,
      b2bSelectedPos = [],
    } = this.props;

    const isB2bSale = _get(sale, 'isB2b');
    const isSecureSale = sale.saleDetails.some(sd => sd.article.id === secureSaleArticleId);
    const saleHasCardif = sale.saleDetails.some(sd => [cardif, teleshoppingCardif, renewalCardif].includes(sd.article.id) && sd.status !== 'returned');

    const { observation, cause } = this.state;
    const return_total = this._isReturningTotally();
    return (
      <div>
        <CustomDialog
          open={open || openManual}
          loading={requestSend}
          disabled={requestSend || this._disabledSubmit(return_total)}
          disableBackdropClick={true}
          onClose={this._handleClose}
          onConfirm={() => this._handleSave(return_total)}
          title={
            openManual ? `Devolución manual de venta` : `Devolución de venta`
          }
          contentText={`Para poder devolver ${!return_total ? 'parcialmente' : 'totalmente'
            } esta venta, debe escoger ${!return_total
              ? 'los artículos que desee devolver, la cantidad y un causal de devolución para cada uno. Además,'
              : 'un causal de devolución que aplicará a todos los artículos de esta y,'
            } si lo desea, podrá añadir una observación.`}
          buttonText={'DEVOLVER'}
          headerSecondary={
            ((!openManual && this.props.canReturnSale) || (openManual && this.props.canReturnArticlesSaleManual && this.props.canReturnSaleManual)) &&
            <Toggle
              name="return_total"
              value={return_total}
              onChange={this._handleActive}
              inactiveLabel="Parcial"
              activeLabel="Total"
              disabled={hasPendingPartialReturn || isSecureSale && !saleHasCardif}
            />
          }
        >
          <Grid container>
            {!return_total && (
              <ReturnPartially
                sale={sale}
                setArticles={this._handleArticles}
                cardif={cardif}
                isSecureSale={isSecureSale}
                teleshoppingCardif={teleshoppingCardif}
                renewalCardif={renewalCardif}
                cardifLayerTwoId={cardifLayerTwoId}
                revokeSaleCausals={this.props.revokeSaleCausals}
                items={this.state.items}
                nItems={this.state.nItems}
                setItems={this.setState.bind(this)}
                newArticle={this.newArticle}
                isB2bSale={isB2bSale}
                secureSaleArticleId={secureSaleArticleId}
              />
            )}
            {return_total && (
              <Fade in={true}>
                <Grid item xs={12}>
                  <SimpleSelect
                    required
                    label="Causal de Devolución"
                    name="cause"
                    options={this._getCausal(sale)}
                    value={cause}
                    onChange={this._handleChange}
                    disabled={false}
                    fullWidth
                    margin="normal"
                    variant="outlined"
                  />
                </Grid>
              </Fade>
            )}
            <Grid item xs={12}>
              <TextField
                label="Comentario"
                name="observation"
                margin="normal"
                variant="outlined"
                onChange={this._handleChange}
                value={observation}
                multiline={true}
                rows={2}
                disabled={requestSend}
                inputProps={{ maxLength: 199 }}
                fullWidth
              />
            </Grid>
          </Grid>
        </CustomDialog>
      </div>
    );
  }
}

const styles = (/* theme */) => ({});

ReturnSale.propTypes = {
  open: PropTypes.bool
};

export default withStyles(styles, { withTheme: true })(ReturnSale);

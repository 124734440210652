import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Grid, withStyles, TextField } from '@material-ui/core';
import CustomDialog from '../../../shared/Modals/CustomDialog';
import _isEmpty from 'lodash/isEmpty';
import FlashMessage from '../../../shared/Alerts/Flash/FlashMessage';
import SimpleTable from '../../../shared/Tables/SimpleTable';
import { _moneyFormat } from '../../../../helpers/utilityFunctions';
import Movements from '../../../shared/Movements/Movements';
import _get from 'lodash/get';

class SaleReturnDetailForm extends Component {
  state = {
    comment: ''
  };

  _handleSave = () => {
    const type = this._getStatus();
    const {
      saleReturnDetail: {
        sale_return_details: [{ id }]
      }
    } = this.props;

    switch (type) {
      case 'registered':
        this.props.onReview(id, this.state.comment);
        break;
      case 'reviewed':
        this.props.onApprove(id, this.state.comment);
    }
  };

  _handleRevoke = () => {
    const {
      saleReturnDetail: {
        sale_return_details: [{ id }]
      }
    } = this.props;
    this.props.onRevoke(id, this.state.comment);
  };

  _handleChange = e => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };
  _getSaleReturnDetails = () => {
    const { saleReturnDetail } = this.props;
    return saleReturnDetail.sale_return_details.map(srd => {
      return {
        article: srd.sale_detail.article.name,
        amount: srd.amount,
        type: srd.return_type == 'canceled' ? 'Anulación' : 'Devolución',
        cause: srd.cause,
        value: _moneyFormat(
          (parseInt(srd.sale_detail.value) + parseInt(srd.sale_detail.iva)) *
          parseInt(srd.amount)
        )
      };
    });
  };

  _getStatus = () => {
    const {
      saleReturnDetail: { sale_return_details }
    } = this.props;
    return sale_return_details[0].status;
  };

  _getOrder = () => {
    const {
      saleReturnDetail: { sale_return_details }
    } = this.props;
    return sale_return_details[0].order_id || sale_return_details[0].id;
  };

  _getActionText = () => {
    const { canReviewReturnSale, canApproveReturnSale } = this.props;
    const type = this._getStatus();
    switch (type) {
      case 'registered':
        if (canReviewReturnSale) return 'Revisar';
        return null;
      case 'reviewed':
        if (canApproveReturnSale) return 'Aprobar';
        return null;
      default:
        return null;
    }
  };

  _getRevokeActionText = () => {
    const { canRevokeReturnSale } = this.props;
    const type = this._getStatus();
    switch (type) {
      case 'registered':
        if (canRevokeReturnSale) return 'Rechazar';
        return null;
      case 'reviewed':
        if (canRevokeReturnSale) return 'Rechazar';
        return null;
      default:
        return null;
    }
  };
  _isCreatingOrder = () => {
    const order = this._getOrder();
    if (this.props.companyName === 'GDG') return null;
    return order ? order.toString().includes('temp_') : null;
  };

  _getDescription = status => {
    switch (status) {
      case 'registered':
        return 'Solicitud';
      case 'reviewed':
        return 'Revisión';
      case 'revoked':
        return 'Rechazo';
      case 'approved':
        return 'Aprobación';
      default:
        return '';
    }
  };

  render() {
    const {
      saleReturnDetail,
      classes,
      open = true,
      isSaving,
      isRevoking,
      onClose
    } = this.props;
    const { comment } = this.state;
    const movements = saleReturnDetail.movements;
    return (
      <div>
        <CustomDialog
          open={open}
          loading={isSaving}
          loadingSecondary={isRevoking}
          disabled={this._isCreatingOrder()}
          disableBackdropClick={false}
          headerSecondary={
            this._isCreatingOrder() ? (
              <Grid item className={classes.flashMessage}>
                <FlashMessage
                  variant="label"
                  message={'Orden en proceso de creación...'}
                />
              </Grid>
            ) : (
              <div />
            )
          }
          onClose={onClose}
          onConfirm={this._handleSave}
          title={`Solicitud de devolución ${_get(
            saleReturnDetail,
            "['sale_return_details'][0]['return_type']"
          ) == 'returned_manual'
              ? 'manual'
              : ''
            }`}
          contentText={''}
          buttonText={this._getActionText()}
          buttonSecondaryText={this._getRevokeActionText()}
          onConfirmSecondary={this._handleRevoke}
          disabledSecondary={_isEmpty(comment) || this._isCreatingOrder()}
          classes={{
            root: classes.dialogRoot
          }}
        >
          <Grid container spacing={16}>
            {saleReturnDetail.movements && (
              <Movements movements={saleReturnDetail.movements} />
            )}
            <Grid item xs={12}>
              <SimpleTable
                headers={[
                  {
                    name: 'article',
                    title: 'Artículo'
                  },
                  {
                    name: 'amount',
                    title: 'Cantidad'
                  },
                  {
                    name: 'cause',
                    title: 'Causa'
                  },
                  {
                    name: 'value',
                    title: 'Valor'
                  }
                ]}
                rows={this._getSaleReturnDetails()}
              />
            </Grid>
            <div className={classes.textContainer}>
              <div className={classes.subContainer}>
                {saleReturnDetail.contract && (
                  <p className={classes.titleContainer}>
                    {'# Contrato: '}
                    <a className={classes.subText}>
                      {saleReturnDetail.contract}
                    </a>
                  </p>
                )}
                {saleReturnDetail.tag == 'deferred-with-covid-relief' &&
                  (this.props.canReviewReturnSale ||
                    this.props.canApproveReturnSale ||
                    this.props.canRevokeReturnSale) && (
                    <p className={classes.subtitleContainer}>
                      {'Venta con cuotas diferidas por covid-19.'}
                    </p>
                  )}
              </div>
              <div className={classes.commentsContainer}>
                {movements.map(mov => {
                  return (
                    <p className={classes.titleContainer} key={mov.id}>
                      {`${this._getDescription(mov.status)}: `}
                      <a className={classes.subText}>{mov.comment}</a>
                    </p>
                  );
                })}
              </div>
            </div>
            {!this._isCreatingOrder() && this._getActionText() && (
              <Grid item xs={12}>
                <TextField
                  label="Comentarios*"
                  name="comment"
                  margin="normal"
                  variant="outlined"
                  onChange={this._handleChange}
                  value={comment}
                  multiline={true}
                  rows={2}
                  inputProps={{ maxLength: 199 }}
                  fullWidth
                />
              </Grid>
            )}
          </Grid>
        </CustomDialog>
      </div>
    );
  }
}

const styles = theme => ({
  flashMessage: {
    marginTop: theme.spacing.unit / 1.5,
    justifyContent: 'flex-end'
  },
  container: {
    marginTop: theme.spacing.unit,
    marginBottom: theme.spacing.unit * 2
  },
  dialogRoot: {
    width: 800
  },
  topInput: {
    marginTop: theme.spacing.unit * 2
  },
  subtitleContainer: {
    color: '#DB4945 !important',
    fontWeight: 'bold',
    fontFamily: theme.typography.fontFamily,
    fontSize: '14px',
    paddingTop: '6px',
    margin: `0px ${theme.spacing.unit * 3}px `,
    marginLeft: theme.spacing.unit * 20
  },
  commentsContainer: {
    marginTop: theme.spacing.unit * 1
  },
  textContainer: {
    display: 'block'
  },
  subContainer: {
    display: 'flex'
  },
  subText: {
    color: theme.typography.color.light,
    paddingLeft: theme.spacing.unit / 2,
    fontWeight: 'normal',
    fontFamily: theme.typography.fontFamily,
    fontSize: '14px',
    paddingRight: '81px',
    wordBreak: 'break-all'
  },
  titleContainer: {
    color: theme.typography.color.main,
    fontWeight: 'bold',
    fontFamily: theme.typography.fontFamily,
    fontSize: '14px',
    paddingTop: '6px',
    margin: `0px ${theme.spacing.unit * 3}px `,
    overflowWrap: 'anywhere'
  },
  heading: {
    color: 'rgba(12,31,44,0.87)',
    fontSize: theme.typography.pxToRem(15),
    fontWeight: 'bold',
    fontFamily: theme.typography.fontFamily
  },
  comments: {
    marginTop: theme.spacing.unit * 2
  }
});

SaleReturnDetailForm.propTypes = {
  open: PropTypes.bool
};

export default withStyles(styles, { withTheme: true })(SaleReturnDetailForm);
